<template>
  <form id="contact-form" method="post" :action="`${baseUrl}form/index.php`">

    <div class="form-content">
      <h1 class="form-header">Kontakt</h1>

      <div class="input-container">
        <label class="input-label" for="vorname">Vorname</label>
        <input class="input" type="text" name="name" id="name" required>
      </div>

      <div class="input-container">
        <label class="input-label" for="nachname">Nachname</label>
        <input class="input" type="text" name="sirname" id="sirname" required>
      </div>
      <div class="input-container">
        <label class="input-label" for="number">Rufnummer</label>
        <input class="input" type="tel" id="phonenumber" name="phonenumber" required>
      </div>
      <div class="input-container">
        <label class="input-label" for="email">E-Mail <small>(optional)</small></label>
        <input class="input" type="email" name="email" id="email" required>
      </div>

      <div class="input-container">
        <label class="input-label" for="textarea">Ihre Nachricht</label>
        <textarea class="input text-area" name="message" id="textarea"></textarea>
        <span class="counter"></span>
      </div>

      <div class="privacy-policy">
        <label for="acceptPrivacyPolicy">
          <router-link to="/datenschutzerklaerung">Ich stimme der Datenschutzerklärung zu.</router-link>
        </label>
        <input type="checkbox" id="accept-privacy-policy" name="acceptPrivacyPolicy" value="acceptPrivacyPolicy" required>
      </div>

      <input class="submit-button" type="submit" name="submit" value="Senden">
    </div>
  </form>
</template>

<script>

export default {
  name: 'ContactForm',

  data() {
    return {
      isOrder: true,
      baseUrl: process.env.BASE_URL
    }
  },

  mounted() {
    this.inputAnimation()
  },

  methods: {
    inputAnimation() {
      document.querySelectorAll('.input').forEach(input => {
        if (input.value !== "" || input.placeholder !== "") {
          input.parentElement.classList.add('focused')
          input.classList.add('filled')
        }

        input.addEventListener('focus', () => {
          input.parentElement.classList.add('focused')
        })

        input.addEventListener('blur', () => {
          let inputValue = input.value

          if (inputValue === "" & input.placeholder === "") {
            input.classList.remove('filled')
            input.parentElement.classList.remove('focused')
          } else {
            input.classList.add('filled')
          }
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
#contact-form
  color $sec-blue
  background #222
  width 100%
  height fit-content
  display flex
  flex-direction column
  align-items center
  padding 25px

  .form-content
    display flex
    flex-direction column
    align-items center
    width 80%

    .form-header
      margin-bottom 25px

    .input-container
      position relative
      width 80%
      margin 15px 0

      &.focused
        .input-label
          transform translateY(-37px)
          color $main-white
          left 0

      .input-label
        position absolute
        left 2.5%
        top 15px
        font-size 15px
        color $main-white
        pointer-events none
        transition all 150ms ease-out

      .input
        color $sec-blue
        position relative
        width 90%
        height 50px
        border-radius 15px
        outline 0
        border 0
        background $main-white-transparent1
        padding-left 2.5%
        padding-right 7.5%

        &::placeholder
          color $main-white
          opacity 0.5

        &:focus, &.filled
          background $main-white-transparent2

        &.text-area
          height 160px
          resize none
          width 90%
          padding 5%

    .address
      width 80%

      .address-row-1
      .address-row-2
        display flex
        flex-direction row
        flex-wrap wrap

        .housenumber
        .zip-code
          width unset
          min-width 50px

        .streetname
        .city
          width unset
          min-width 200px
          margin-right 20px
          flex-grow 1

    .others
      display flex
      justify-content flex-start
      width 80%
      margin 15px 0
      flex-direction column
      color $main-white

      .others-header
        margin-bottom 15px

      .options-container
        display flex
        justify-content flex-start
        align-items center
        flex-direction row
        flex-wrap wrap

        .option
          display flex
          padding 0 25px 0 0
          align-items center

          input[type="checkbox"]
            appearance none
            background-color #fff
            width 20px
            height 20px
            border 2px solid $main-white
            border-radius 5px
            transform translateY(-1px)

            display grid
            place-content center

            &::before
              content ""
              width 10px
              height 10px
              transform scale(0)
              transition 120ms transform ease-in-out
              background $sec-blue
              border-radius 2px

            &:checked
              &::before
                transform scale(1)

    .privacy-policy
      display flex
      align-items center
      justify-content center
      width 80%
      color $main-white
      transition .4s
      margin-bottom 25px

      &:hover
        color $sec-blue

      input[type="checkbox"]
        appearance none
        background-color #fff
        width 20px
        height 20px
        border 2px solid $main-white
        border-radius 5px
        transform translateY(-1px)

        display grid
        place-content center

        &::before
          content ""
          width 10px
          height 10px
          transform scale(0)
          transition 120ms transform ease-in-out
          background $sec-blue
          border-radius 2px

        &:checked
          &::before
            transform scale(1)

    .submit-button
      width fit-content
      height fit-content
      background $sec-blue
      color $main-white
      padding 11px 20px
      border-radius 5px
      border 0
      cursor pointer
      transition 0.5s

      &:hover
        background $main-white
        color $sec-blue

@media only screen and (max-width: $tablet-screen)
  #contact-form
    .form-content
      width 100%
</style>
