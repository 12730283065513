<template>
  <div class="slides" :title="imgAlt" :style="'background-image: url(' + require('@/assets/images/' + imgLink) + ')'"
    :class="{ active: isActive }">
    <div class="wall" v-if="showWall" />
    <div class="content-container">
      <p v-if="text">{{ text }}</p>
      <h1 class="desktop-header" v-if="header">{{ header }}</h1>
      <h2 class="mobile-header" v-if="header">{{ header }}</h2>
      <router-link class="slide-button" v-if="buttonText" :to="buttonLink">{{ buttonText }}</router-link>
      <a v-if="buttonPdf" class="slide-button" :href="buttonPdf" download><span>{{ buttonPdfText }}</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageCard',

  props: {
    isActive: Boolean,
    imgLink: String,
    imgAlt: String,
    buttonText: String,
    buttonLink: String,
    header: String,
    text: String,
    showWall: Boolean,
    buttonPdf: String,
    buttonPdfText: String
  }
}
</script>

<style lang="stylus" scoped>
.slides
  position absolute
  width 100%
  height 100%
  background-size cover
  background-position center
  opacity 0
  pointer-events none
  color $main-white
  transition .4s

  .wall
    position absolute
    background #22222257
    width 100%
    height 100%
    pointer-events none

  .content-container
    position absolute
    display flex
    justify-content center
    align-items center
    flex-direction column
    top 0
    left 10%
    width 80%
    height 100%

    .desktop-header
      display block

    .mobile-header
      display none

  &.active
    opacity 1
    pointer-events all

  .slide-button
    width fit-content
    height fit-content
    background $sec-blue
    padding 11px 20px
    border-radius 5px
    transition .5s

    &:hover
      background $sec-black
      color $sec-blue

  h1
    font-size 30px

@media only screen and (max-width: $mobile-screen)
  .slides
    .content-container
      .desktop-header
        display none

      .mobile-header
        display block
</style>
