<template>
  <main id="home">
    <SlidesShow :slidesNumber=3 name="header-slide-show">
      <ImageCard imgLink='home/laden-ansicht-front.png' imgAlt='Laden Front Ansicht' buttonLink='' buttonText=''
        header='Herzlich willkommen bei Fischwerk!' text='' showWall />
      <ImageCard imgLink='home/fish-plate.png' imgAlt='Fisch Teller' buttonPdf='fischwerk-speisekarte.pdf'
        buttonPdfText='Speisekarte' header='Frischfisch und Räucherfisch' text='Erfahren Sie mehr über unseren'
        showWall />
      <ImageCard imgLink='home/fish-plate-2.png' imgAlt='fisch plate image' buttonLink='/kontakt' buttonText='Kontakt'
        header='Kontaktieren Sie uns für unsere aktuelle Fische zur Bestellung' text='Interessiert an frischem Fisch?'
        showWall />
    </SlidesShow>
  </main>
</template>

<script>
import SlidesShow from '@/components/SlidesShow.vue'
import ImageCard from '@/components/ImageCard.vue'

export default {
  name: 'vHome',

  components: {
    SlidesShow,
    ImageCard
  }
}
</script>

<style lang="stylus" scoped>
#home
  .slides-show
    height 77vh

  p
    width 80%

  h1, h2
    max-width 90%
    color $sec-blue

@media only screen and (max-width: $mobile-screen)
  #home
    .slides-show
      height 87vh
</style>
