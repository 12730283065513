<template>
  <TheMainNavigation />
  <router-view />
  <TheBottomFooter />
</template>

<script>
// Components
import TheMainNavigation from './components/TheMainNavigation.vue'
import TheBottomFooter from './components/TheBottomFooter.vue'

export default {
  name: 'App',
  components: {
    TheMainNavigation,
    TheBottomFooter
  }
}
</script>

<style lang="stylus">
#app
  display flex
  flex-direction column
  min-height 100%

  main
    flex-grow 1
    flex-shrink 0
    // margin-bottom 60px
    overflow hidden
    display flex
    flex-direction column
    align-items center
</style>
