<template>
  <main id="kontakt">
    <h1>Frischen oder geräucherten Fisch können Sie während unserer Öffnungszeiten telefonisch oder jederzeit via E-Mail
      vorbestellen
    </h1>
    <div class="icon-cards-container">
      <IconTextCard imgLink="phone-icon.svg" imgAlt="Phone icon" header="Telefonnummer" text="0221 98944665"
        textLink="tel:022198944665" />
      <IconTextCard imgLink="email-icon.svg" imgAlt="E-mail icon" header="Email" text="bestellung@fisch-werk.de"
        textLink="mailto:bestellung@fisch-werk.de" />
    </div>

    <h1>Oder nutzen Sie unser Kontakt Formular!</h1>
    <ContactForm />
  </main>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import IconTextCard from '@/components/IconTextCard.vue'

export default {
  name: 'vKontakt',

  components: {
    ContactForm,
    IconTextCard
  }
}
</script>

<style lang="stylus" scoped>
#kontakt
  p
    max-width 80%

  h1, h2
    max-width 90%
    text-align center

  h1
    font-family OpenSans-Bold
    color $sec-black

  .icon-cards-container
    display flex
    width 50%
    margin-top 25px
    justify-content space-around
    flex-wrap wrap
</style>
