<template>
  <footer id="bottom-footer">
    <!-- Left side content -->
    <div class="left-side-content">
      <MqResponsive target="tablet+">
        <h4>
          Kontaktieren Sie uns:
        </h4>
      </MqResponsive>
      <!-- Phone icon number with link to the phone app -->
      <div class="phone-number">
        <img src="../assets/images/icons/phone-icon.svg" alt="Phone icon">
        <a class="link-phone link-animtaion" href="tel:022198944665" data-replace="0221 98944665"><span>0221
            98944665</span></a>
      </div>

      <!-- Email icon with link to the email app -->
      <div class="email">
        <img src="../assets/images/icons/email-icon.svg" alt="Email icon">
        <a class="link-email link-animtaion" href="mailto:bestellung@fisch-werk.de"
          data-replace="bestellung@fisch-werk.de"><span>bestellung@fisch-werk.de</span></a>
      </div>
    </div>

    <!-- Right side content -->
    <div class="right-side-content">
      <MqResponsive target="tablet+">
        <h4>
          Weitere Links:
        </h4>
      </MqResponsive>
      <router-link class="link-animtaion" to="/impressum" data-replace="Impressum"><span>Impressum</span></router-link>
      <router-link class="link-animtaion" to="/datenschutzerklaerung"
        data-replace="Datenschutzerklärung"><span>Datenschutzerklärung</span></router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BottomFooter'
}
</script>

<style lang="stylus" scoped>
#bottom-footer
  display flex
  max-width 100%
  background $sec-black
  color $main-white
  flex-direction row
  align-items flex-start
  justify-content space-between
  padding 10px 75px
  overflow hidden

  .right-side-content
  .left-side-content
    display flex
    flex-direction column
    justify-content center
    align-items flex-start

    h4
      color $sec-blue
      font-family OpenSans-Regular

  .left-side-content
    .phone-number
    .email
      margin 5px 0
      display flex
      align-items center

      img
        filter $sec-blue-filter
        margin-right 5px
        width 30px

  .right-side-content
    a
      margin 5px 0

@media only screen and (max-width: $tablet-screen)
  #bottom-footer
    flex-direction column
    justify-content center
    align-items center

    .left-side-content
    .right-side-content
      flex-direction row
      align-items center

    .left-side-content
      .phone-number
      .email
        margin 5px 15px

    .right-side-content
      a
        margin 5px 15px
</style>
